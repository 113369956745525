import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";

const activeClassName = "text-gold-bright hover:!scale-100 cursor-default";
const classes = "px-4 py-4 hover:transform hover:scale-110";

const Nav = ({ className, style }) => (
  <nav className={className} style={style}>
    <Link to="/" activeClassName={activeClassName} className={classes}>
      HOME
    </Link>
    <Link to="/about" activeClassName={activeClassName} className={classes}>
      ABOUT
    </Link>
    <Link to="/contact" activeClassName={activeClassName} className={classes}>
      CONTACT
    </Link>
  </nav>
);

Nav.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Nav;
