import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import styled from "styled-components";

import Logo from "./Logo";
import MobileDrawer from "./MobileDrawer";
import Nav from "./Nav";

const Content = styled.div`
  display: flex;
  width: 1140px;
  position: relative;
`;

const Header = ({ className }) => (
  <div
    className={classnames(
      "fixed top-0 z-10 w-full bg-blue-navy flex justify-center px-8 py-2",
      className
    )}
  >
    <Content>
      <div className="z-10 flex justify-center md:justify-start flex-1">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <Nav className="hidden md:flex flex-1 justify-center items-center text-primary text-lg" />
      <div className="hidden md:flex flex-1 justify-end items-center">
        <a href="tel:+12107734905">
          <button
            type="button"
            className="py-1 px-2 rounded bg-gold-bright hover:bg-white text-blue"
            aria-label="Call Button"
          >
            <FontAwesomeIcon icon={faPhone} size="2x" />
          </button>
        </a>
      </div>
      <div className="md:hidden absolute w-full h-full flex flex-1 items-center">
        <MobileDrawer className="flex-1" />
        <div className="flex-1 flex justify-end">
          <a href="tel:+12107734905" className="z-20">
            <button
              type="button"
              aria-label="Mobile Call Button"
              className="py-1 px-2 rounded text-white hover:text-gold-bright"
            >
              <FontAwesomeIcon icon={faPhone} size="2x" />
            </button>
          </a>
        </div>
      </div>
    </Content>
  </div>
);

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
