import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const APP_URL = "https://www.sotoinvestigations.com";
const DEFAULT_IMAGE = "/src/assets/logo.png";

const SearchEngineOptimization = ({ lang, metadata, meta }) => (
  <HelmetWrapper
    lang={lang}
    title={metadata?.title}
    description={metadata?.description}
    robots={metadata?.robots}
    canonical={metadata?.canonical}
    ogType={metadata["og:type"]}
    ogLocale={metadata["og:locale"]}
    ogUrl={metadata["og:url"]}
    ogSiteName={metadata["og:site_name"]}
    ogImage={metadata["og:image"]}
    modifiedTime={metadata["article:modified_time"]}
    twitterCard={metadata["twitter:card"]}
    twitterLabel={metadata["twitter:label1"]}
    twitterData={metadata["twitter:data1"]}
    twitterImage={metadata["twitter:image"] || metadata["og:image"]}
    meta={meta}
  />
);

SearchEngineOptimization.defaultProps = {
  metadata: {},
  meta: [],
};

SearchEngineOptimization.propTypes = {
  metadata: PropTypes.object.isRequired,
  meta: PropTypes.arrayOf(PropTypes.object),
};

const imageCleanUp = (image) => `${APP_URL}${image}`;

const HelmetWrapper = ({
  lang,
  title,
  description,
  ogType,
  ogLocale,
  ogUrl,
  ogSiteName,
  ogImage,
  canonical,
  modifiedTime,
  twitterCard,
  twitterLabel,
  twitterData,
  twitterImage,
  robots,
  meta,
}) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    meta={[
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: ogType,
      },
      {
        property: `og:locale`,
        content: ogLocale,
      },
      {
        property: `og:url`,
        content: `${APP_URL}${ogUrl}`,
      },
      {
        property: `og:site_name`,
        content: ogSiteName,
      },
      {
        property: `og:image`,
        content: imageCleanUp(ogImage),
      },
      {
        property: `article:modified_time`,
        content: modifiedTime,
      },
      {
        name: `twitter:image`,
        content: imageCleanUp(twitterImage),
      },
      {
        name: `twitter:label1`,
        content: twitterLabel,
      },
      {
        name: `twitter:data1`,
        content: twitterData,
      },
      {
        name: `twitter:card`,
        content: twitterCard,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
      {
        name: `robots`,
        content: robots,
      },
      {
        name: `msapplication-config`,
        content: `manifest/browserconfig.xml`,
      },
    ].concat(meta)}
  >
    <link rel="canonical" href={`${APP_URL}${canonical}`} />
    <link rel="apple-touch-icon" sizes="180x180" href="src/assets/logo.png" />
    <link rel="mask-icon" href="src/assets/logo.png" />
  </Helmet>
);

HelmetWrapper.defaultProps = {
  lang: "en",
  description: ``,
  ogImage: DEFAULT_IMAGE,
  ogType: "website",
  ogLocale: "en_US",
  ogSiteName: "Soto Investigations",
  twitterCard: "summary_large_image",
  twitterLabel: "Est. reading time",
  twitterData: "1 minute",
  twitterImage: DEFAULT_IMAGE,
};

HelmetWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  lang: PropTypes.string,
  description: PropTypes.string,
  ogType: PropTypes.string,
  ogLocale: PropTypes.string,
  ogUrl: PropTypes.string.isRequired,
  ogSiteName: PropTypes.string,
  ogImage: PropTypes.string,
  twitterImage: PropTypes.string,
  canonical: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SearchEngineOptimization;
