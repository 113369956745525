import React from "react";

const Logo = () => (
  <div className="flex p-1 font-times-new-roman text-gold-bright">
    <div className="flex items-center text-4xl">CSI</div>
    <div className="border border-l-1 border-gold-bright mx-3" />
    <div className="text-xl">
      <div><div className="inline-block">Chris</div></div>
      <div><div className="inline-block">Soto</div></div>
      <div><div className="inline-block">Investigations</div></div>
    </div>
  </div>
);

export default Logo;
