import Helmet from "react-helmet";
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Header from "./Header";
import logo from "../assets/logo.png";
import SearchEngineOptimization from "./SearchEngineOptimization";

const Layout = ({ children, className, metadata }) => (
  <>
    <Helmet>
      <link rel="icon" href={logo} />
    </Helmet>
    <SearchEngineOptimization metadata={metadata} />
    <div className={classnames("h-full flex flex-col", className)}>
      <Header />
      <div className="flex-grow" style={{ marginTop: "108px" }}>
        {children}
      </div>
    </div>
  </>
);

Layout.defaultProps = {
  metadata: {},
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  metadata: PropTypes.object.isRequired,
};

export default Layout;
