import React, { useState } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

import Nav from "./Nav";

const MobileDrawer = ({ className }) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);

  return (
    <div className={classnames("flex items-center text-primary", className)}>
      <button
        type="button"
        onClick={toggle}
        aria-label="Mobile Nav Toggle"
        className="z-20 hover:text-gold-bright"
      >
        <FontAwesomeIcon icon={isOpen ? faXmark : faBars} size="2x" />
      </button>
      <Nav
        className={classnames(
          "fixed flex flex-col left-0 bg-blue-gray transform transition-all duration-200 ease-in-out pl-4 pr-8 h-full opacity-95",
          { "translate-x-0": isOpen, "-translate-x-full": !isOpen }
        )}
        style={{ top: "108px" }}
      />
    </div>
  );
};

MobileDrawer.propTypes = {
  className: PropTypes.string,
};

export default MobileDrawer;
